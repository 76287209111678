import ApiBase from '@/api/base'
import { notify } from '@/helpers/notifyHelper'

class ApiNewPositionRequest {
	static create (self) {
		const form = self.form
		self.http('post', `${ApiBase.baseUrl()}/new-position-requests/create`, form)
			.then(response => {
				this.getAll(self)
				self.hideDialog()
				notify(self, 'success', self.trans(response.data.message))
			})
			.catch(err => {
				self.showErrors(err.response.data.errors)
			})
	}
	static update (self, id) {
		const form = self.form
		const authHeader = ApiBase.authHeaders()
		self.http('put', `${ApiBase.baseUrl()}/new-position-requests/${id}`, form, authHeader)
			.then(response => {
				this.getAll(self)
				self.hideDialog()
				notify(self, 'success', self.trans(response.data.message))
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
	static getAll (self) {
		self.http('get', `${ApiBase.baseUrl()}/new-position-requests/`)
			.then(response => {
				ApiBase.setToStore(null, 'setNewPositionRequests', response.data)
			})
	}
	static _delete (self, id) {
		self.http('delete', `${ApiBase.baseUrl()}/new-position-requests/delete/${id}`, null, ApiBase.authHeaders())
			.then(response => {
				notify(self, 'success', self.trans(response.data.message))
				this.getAll(self)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
}

export default ApiNewPositionRequest
