import ApiBase from '@/api/base'
import {notify} from '@/helpers/notifyHelper'

class ApiSocialMedia {
	static create (self) {
		const form = self.form
		const authHeader = ApiBase.authHeaders()
		self.http('post', `${ApiBase.baseUrl()}/social-medias/create`, form, authHeader)
			.then(response => {
				this.getAll(self)
				self.hideDialog()
				notify(self, 'success', response.data.message)
		})
	}
	static update (self, id) {
		const form =self.form
		const authHeader = ApiBase.authHeaders()
		self.http('put', `${ApiBase.baseUrl()}/social-medias/${id}`, form, authHeader)
			.then(response => {
				this.getAll(self)
				self.hideDialog()
				notify(self, 'success', response.data.message)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
	static getAll (self) {
		self.http('get', `${ApiBase.baseUrl()}/social-medias/`)
			.then(response => {
				ApiBase.setToStore(null, 'setSocialMediaList', response.data)
			})
	}
	static _delete (self, id) {
		self.http('delete', `${ApiBase.baseUrl()}/social-medias/delete/${id}`, null, ApiBase.authHeaders())
			.then(response => {
				notify(self, 'success', response.data.message)
				this.getAll(self)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
}

export default ApiSocialMedia
